import React, { useEffect, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

import Layout from '../components/layout';
import Meta from '../components/meta';
import { KenyaMapIcon } from '../components/svgs';
import { Button, Header, NewsTicker, Text } from '../components';
import { isBrowser } from '../helpers/isBrowser';

const IndexPage = ({ location, data }) => {
	const { storyblokEntry } = data;
	const pagedata = JSON.parse(storyblokEntry.content);

	const exitEl = useRef();
	const leftCloud = useRef();
	const rightCloud = useRef();
	const airplane = useRef();
	const cloudTimeline = gsap.timeline({ paused: true });

	useEffect(() => {
		if(isBrowser()) {
			gsap.registerPlugin(MotionPathPlugin);
			if(leftCloud.current) {
				gsap.set(leftCloud.current, {
					left: 0,
					y: '-30vh',
					xPercent: -100,
					opacity: 1,
				});
				cloudTimeline.to(leftCloud.current, {
					xPercent: 100,
					x: '100vw',
					duration: 120,
					ease: 'none',
					repeat: -1,
				}).play();
			}
			if(rightCloud.current) {
				gsap.set(rightCloud.current, {
					right: 0,
					y: '30vh',
					xPercent: 100,
					opacity: 1,
				});
				cloudTimeline.to(rightCloud.current, {
					xPercent: -100,
					x: '-100vw',
					duration: 120,
					ease: 'none',
					repeat: -1,
				}, 0).play();
			}
			if(airplane.current) {
				gsap.set(airplane.current, {
					opacity: 1,
					rotate: -90,
				});
				gsap.to(airplane.current, {
					motionPath: {
						path: '#airplane-path',
						align: '#airplane-path',
						alignOrigin: [.5, .5],
						autoRotate: 135,
					},
					repeat: -1,
					ease: 'none',
					duration: 24,
				});
			}
		}
	}, []);

	return (
		<Layout>
			<Meta title="Gå på opdagelse i Kenyakortet" ogTitle="Gå på opdagelse i Kenyakortet" />
			<Header hasBack={false} color="#ffffff" location={location}/>
			<div
				className="relative flex flex-col items-center justify-center w-screen h-screen overflow-hidden landing-page"
				style={{backgroundImage: `url(${pagedata.body[0].background_image.filename})` || '' }}
			>
				<img ref={airplane} width="400" className="absolute opacity-0" src="/airplane.png" />
				<img ref={leftCloud} className="absolute opacity-0" src="/cloud_1.png" />
				<img ref={rightCloud} className="absolute opacity-0" src="/cloud_1.png" />
				<figure className="absolute w-full">
					<svg viewBox="0 0 1600 900" className="opacity-0">
						<path fill="none" id="airplane-path" d="M 2000 1125 Q 1400 900 1000 700 C 600 500 650 200 900 200 Q 1150 200 1150 400 Q 1150 650 650 700 C 150 750 200 600 150 400 Q 100 200 -200 -125"></path>
					</svg>
				</figure>
				<div ref={exitEl} className="absolute top-0 left-0 w-full h-full origin-left transform scale-x-0 bg-primary"></div>
				<KenyaMapIcon fill="#fff" width="30%"/>
				<Text type='body1' className="z-10 max-w-sm mt-40 text-center md:max-w-35 text-white-100">{pagedata.body[0].body}</Text>
				<div className="z-10 flex my-64">
					<Button to="/laerer" variant="linkButton" className="mr-48">Lærer</Button>
					<Button to="/elev" variant='linkButton'>Elev</Button>
				</div>
				<Text type='body1' className="z-10 font-black text-white-100">
					<span>{pagedata.body[0].sub_body} </span>
					<Link to="/explore" className="mt-40 underline">
						{pagedata.body[0].link_text}
					</Link>
				</Text>
			</div>
			<NewsTicker />
		</Layout>
	);
};

export default IndexPage;

export const query = graphql`
	query indexQuery {
		storyblokEntry(slug: {eq: "index"}) {
			content
		}
	}
`;
